<script>
import View from '@/views/View';

import LoadSections from '@/mixins/load-sections';

export default {
  name: 'FourOhFour',

  metaInfo: { title: 'Oops! No Page Found' },

  extends: View,

  mixins: [LoadSections(['KDA404'])],

  props: {
    id: {
      type: String,
      default: '404'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      'page_title': 'Error 404'
    })
  },
};
</script>
